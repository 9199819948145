import request from '@/service/request'

// 获取绿色通道列表
export const getGreenChannelList = (params) => {
  return request.post('stu/stuGreenChannel/page', params)
}

// 添加绿色通道
export const addGreenChannel = (params) => {
  return request.post('stu/stuGreenChannel/save', params)
}

/* 绿色通道申请  批量通过
* id {string}  申请书id
* userId {string} 申请人id
* auditStatus {number} 状态（3=通过）
* auditUserId {string}  审批人id
*/
export const saveBatchGreenChannel = (params) => {
  return request.post('stu/stuGreenChannel/saveBatch', params)
}

// 条件查询绿色通道
export const getGreenChannelListQuery = (params) => {
  return request.post('/stu/stuGreenChannel/list', params)
}

// 删除绿色通道
export const deleteGreenChannel = (params) => {
  return request.post('/stu/stuGreenChannel/del/' + params)
}

// 根据id获取绿色通道信息
export const getGreenChannelById = (params) => {
  return request.post('/stu/stuGreenChannel/getById/' + params)
}

// 通过后撤销 绿色通道申请
export const cancelGreenChannelOffer = (id) => {
  return request.post(`/stu/stuGreenChannel/backout/${id}`)
}
