<template>
  <el-dialog
    class='review-dialog'
    :title="`${readData.auditStatus&&readData.auditStatus===1?'审核':'查看'}绿色通道申请`"
    :visible.sync='show'
    v-loading='loadData.loading'
    :close-on-click-modal='false'
    width='850px'
    :before-close='close'
  >
    <el-descriptions :column='2' border>
      <el-descriptions-item :span="2" label-class-name='descriptions-item__label' label='申请人'>
        {{ readData.stuName }}
      </el-descriptions-item>
      <el-descriptions-item :span='readData.loanAmount?1:2' label-class-name='descriptions-item__label' label='申请类型'>
        {{ $http.getParamName(readData.channelType, $store.state.systemParam.greenRoadStatus) }}
      </el-descriptions-item>
      <el-descriptions-item v-if='readData.loanAmount' label-class-name='descriptions-item__label' label='贷款金额'>
        <b style='font-size:16px'>{{ readData.loanAmount }} 元</b>
      </el-descriptions-item>
      <el-descriptions-item label-class-name='descriptions-item__label' label='助学贷款申请单'>
        <el-image
          v-if='readData.applyFileImgUrl'
          style='width: 200px; height: auto'
          :src="$fileUrl + '' + readData.applyFileImgUrl"
          :preview-src-list="[$fileUrl + '' + readData.applyFileImgUrl]"
          fit='cover' />
        <span class='font-grey' v-else>无</span>
      </el-descriptions-item>
      <el-descriptions-item label-class-name='descriptions-item__label' label='家庭经济困难证明'>
        <el-image
          v-if='readData.homeHardImgUrl'
          style='width: 200px; height: auto'
          :src="$fileUrl + '' + readData.homeHardImgUrl"
          :preview-src-list="[$fileUrl + '' + readData.homeHardImgUrl]"
          fit='cover'
        >
        </el-image>
        <span class='font-grey' v-else>无</span>
      </el-descriptions-item>
      <el-descriptions-item label-class-name='descriptions-item__label' label='身份证反面照'>
        <el-image
          v-if='readData.idCardBackImgUrl'
          style='width: 200px; height: auto'
          :src="$fileUrl + '' + readData.idCardBackImgUrl"
          :preview-src-list="[$fileUrl + '' + readData.idCardBackImgUrl]"
          fit='cover' />
        <span class='font-grey' v-else>无</span>
      </el-descriptions-item>
      <el-descriptions-item label-class-name='descriptions-item__label' label='身份证正面照'>
        <el-image
          v-if='readData.idCardUpImgUrl'
          style='width: 200px; height: auto'
          :src="$fileUrl + '' + readData.idCardUpImgUrl"
          :preview-src-list="[$fileUrl + '' + readData.idCardUpImgUrl]"
          fit='cover'
        />
        <span class='font-grey' v-else>无</span>
      </el-descriptions-item>
      <el-descriptions-item label-class-name='descriptions-item__label' label='家长身份证正面照'>
        <el-image
          v-if='readData.parentIdCardUpImgUrl'
          style='width: 200px; height: auto'
          :src="$fileUrl + '' + readData.parentIdCardUpImgUrl"
          :preview-src-list="[
                $fileUrl + '' + readData.parentIdCardUpImgUrl
              ]"
          fit='cover' />
        <span class='font-grey' v-else>无</span>
      </el-descriptions-item>
      <el-descriptions-item label-class-name='descriptions-item__label' label='家长身份证反面照'>
        <el-image
          v-if='readData.parentIdCardBackImgUrl'
          style='width: 200px; height: auto'
          :src="$fileUrl + '' + readData.parentIdCardBackImgUrl"
          :preview-src-list="[
                $fileUrl + '' + readData.parentIdCardBackImgUrl
              ]"
          fit='cover' />
        <span class='font-grey' v-else>无</span>
      </el-descriptions-item>
      <!-- 只读时 -->
      <template v-if='readData.auditStatus!==1'>
        <el-descriptions-item :span='2' v-if='readData.auditStatus===2'
                              label-class-name='descriptions-item__label' label='审核意见'>
          {{ readData.auditMsg }}
        </el-descriptions-item>
        <el-descriptions-item :span='2' label-class-name='descriptions-item__label'
                              label='审核意见'>
            <el-tag v-if="readData.auditStatus" :type="$store.state.systemParam.reviewStatus[readData.auditStatus-1].btnType">
              {{ $store.state.systemParam.reviewStatus[readData.auditStatus - 1].label }}
            </el-tag>
        </el-descriptions-item>
      </template>
    </el-descriptions>
    <el-form v-if='readData.auditStatus&&readData.auditStatus===1' class='mt-1' ref='form' :model='formData'
             :rules='comptRules' label-width='130px'>
      <el-form-item prop='auditMsg' label='审核意见'>
        <el-input type='textarea' rows='3' v-model.trim='formData.auditMsg'
                  placeholder='审核不通过时,请输入审核意见' />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click='close'>关 闭</el-button>
      <template v-if='readData.auditStatus === 1'>
        <el-button type='success' @click='handleApprove(3)'
        >审核通过
        </el-button
        >
        <el-button
          type='danger'
          @click='handleApprove(2)'
        >审核不通过
        </el-button>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import { addGreenChannel, getGreenChannelById } from '@/api/greenChannel'
import { copyData } from '@/utils/common-js'
import dialog from '@/vue/mixins/dialog'

export default {
  mixins: [dialog],
  data () {
    return {
      readData: { // 学生的申请表
        id: '',
        userId: '', // 用户id
        stuName: '',
        loanAmount: '',
        auditMsg: '', // 审核信息
        auditStatus: null, // 审核结果(1是未审核，2是审核不通过，3是审核通过)
        channelType: null, // 绿色通道类型(1是贷款，2是分期,3-最低缴费)
        applyFileImgUrl: '', // 助学贷款申请单照url
        homeHardImgUrl: '', // 家庭经济困难证明url
        idCardBackImgUrl: '', // 身份证反面照url
        idCardUpImgUrl: '', // 身份证正面照url
        parentIdCardBackImgUrl: '', // 家长身份证反面照url
        parentIdCardUpImgUrl: '' // 家长身份证正面照url
      },
      formData: { // 审核信息
        id: '',
        auditUserId: JSON.parse(window.sessionStorage.getItem('userInfo')).id, // 审核人用户id
        auditMsg: '', // 审核信息
        auditStatus: null // 审核结果(1是未审核，2是审核不通过，3是审核通过)
      },
      uploadData: '',
      collegeList: []
    }
  },
  computed: {
    // 计算是否需要做表单校验
    comptRules () {
      let _rules = {}
      // 不通过时,必填审核意见
      if (this.formData.auditStatus && this.formData.auditStatus === 2) {
        _rules = {
          auditMsg: { required: true, message: '驳回申请时，清填写拒绝理由。', trigger: 'blur' }
        }
      }
      return _rules
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.id) {
        this.getReadDataById(this.id, getGreenChannelById)
      }
    })
  },
  methods: {
    // 获取申请的学生信息
    async getReadDataById (id) {
      this.loadData.loading = true
      try {
        const res = await getGreenChannelById(id)
        copyData(res.data, this.readData)
        this.formData.id = this.id
      } catch (e) {
      } finally {
        this.loadData.loading = false
      }
    },
    /* 审核 绿色通道申请
    * 1-未审核，2-审核不通过，3-审核通过
     */
    async handleApprove (flag) {
      this.formData.auditStatus = flag
      await this.$nextTick()
      await this.$refs.form.validate((valid) => {
        if (valid) {
          let _msgTitle = '驳回申请'
          let _msgType = 'danger'
          let _msg = ''
          if (flag === 2) {
            _msg = `确定要驳回<b>${this.readData.stuName}</b>的绿色通道申请吗?`
          }
          if (flag === 3) {
            _msgTitle = '通过申请'
            _msgType = 'success'
            const _chanelType = this.readData.channelType === 1 ? `申请类型为 <b style='font-size:16px;'>贷款</b> ,贷款金额为:</b><b style='font-size:18px;color:var(--color-primary)'>${this.readData.loanAmount}元</b>` : (this.readData.channelType === 2 ? '申请类型为 <b>分期</b>' : '申请类型为 <b>最低缴费</b>')
            _msg = `通过申请，<br/>申请人: <b>${this.readData.stuName}</b><br/>${_chanelType}，<br/>是否确定?`
          }
          this.$confirm(_msg, _msgTitle, {
            type: _msgType,
            dangerouslyUseHTMLString: true
          }).then(async () => {
            this.loadData.loading = true
            try {
              await addGreenChannel(this.formData)
              this.$message(`已${_msgTitle}!`,
                {
                  type: _msgType
                }
              )
              this.closed()
            } catch (e) {

            } finally {
              this.loadData.loading = false
            }
          }).catch(() => {
            this.$message('已取消')
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang='scss'>
.review-dialog {
  .el-dialog{
    margin-top: 5dvh !important;
  }
  .el-descriptions {
    .descriptions-item__label {
      width: 150px;
    }
  }
}
</style>
