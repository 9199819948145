<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        :queryInfo.sync="queryInfo"
        :tool-list="['grade', 'college', 'major','keyword']"
        :insert-select-all="['college', 'major']"
        @on-search="renderTable(1)">
        <el-form-item label="审核状态" label-width="80">
          <el-select
            v-model="queryInfo.auditStatus"
            clearable
            size="small">
            <el-option
              v-for="{paramValue,label} in $store.state.systemParam.reviewStatus"
              :key="paramValue"
              :label="label"
              :value="paramValue" />
          </el-select>
        </el-form-item>
        <el-form-item label="申请类型" label-width="80">
          <el-select
            v-model="queryInfo.channelType"
            clearable
            size="small">
            <el-option
              v-for="{paramValue,paramName} in $store.state.systemParam.greenRoadStatus"
              :key="paramValue"
              :label="paramName"
              :value="paramValue" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button">
        <el-button
          v-permission="['greenRoad:review']"
          type="success"
          size="small"
          @click="passReviewHandle"
          :disabled="!passList.length"
        >批量通过
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      border
      stripe
      @selection-change="selectStudent">
      <el-table-column type="selection" width="40" />
      <el-table-column prop="examineNo" label="录取编号" width="90" />
      <el-table-column prop="stuName" label="学生姓名" width="100" />
      <el-table-column prop="collegeName" label="院系" width="200" />
      <el-table-column prop="majorName" label="专业" min-width="200" />
      <el-table-column prop="className" label="班级" min-width="200" />
      <el-table-column prop="managerUserName" label="辅导员" width="100" />
      <el-table-column prop="stuName" label="申请类型" width="80">
        <template v-slot="{row}">
          {{ $http.getParamName(row.channelType, $store.state.systemParam.greenRoadStatus) }}
        </template>
      </el-table-column>
      <el-table-column prop="loanAmount" label="贷款金额" width="100" />
      <el-table-column label="状态" width="80" align="center" fixed="right">
        <template v-slot="{row}">
          <el-tag v-if="row.auditStatus" :type="$store.state.systemParam.reviewStatus[row.auditStatus-1].btnType">
            {{ $store.state.systemParam.reviewStatus[row.auditStatus - 1].label }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="申请时间" width="160" />
      <el-table-column label="操作" width="220" fixed="right">
        <template v-slot="{row}">
          <el-button
            type="primary"
            size="mini"
            @click="showDialog(row.id)"
          >查看详情
          </el-button>
          <template v-if="Number(row.auditStatus) === 1">
            <el-button
              v-permission="['greenRoad:review']"
              type="success"
              size="mini"
              @click="passHandle(row.id)"
            >通过
            </el-button>
            <el-button
              v-permission="['greenRoad:review']"
              type="danger"
              size="mini"
              @click="rejectHandle(row)"
            >不通过
            </el-button>
          </template>
          <template v-if="Number(row.auditStatus) === 3">
            <el-button
              v-permission="['greenRoad:cancel']"
              type="danger"
              size="mini"
              @click="cancel(row)"
            >通过后撤销
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <review-detail
      ref="dialogRef"
      :visible.sync="visibleDialog"
      v-if="visibleDialog"
      @on-close="renderTable" />
  </table-view>
</template>

<script>
import {
  getGreenChannelList,
  addGreenChannel,
  cancelGreenChannelOffer,
  saveBatchGreenChannel
} from '@/api/greenChannel'
import reviewDetail from './dialog/reviewDetail.vue'
import tableView from '@/vue/mixins/table-view'

export default {
  components: {
    reviewDetail
  },
  mixins: [tableView],
  data () {
    return {
      queryInfo: {
        grade: new Date().getFullYear(),
        auditStatus: 1,
        campusId: null,
        collegeId: null,
        majorId: null,
        channelType: null,
        classesId: null
      },
      passList: []
    }
  },
  mounted () {
    this.renderTable(1)
  },
  methods: {
    // 审核通过
    passHandle (id) {
      this.$confirm('通过审核, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.addGreenChannelHttp({
            id,
            auditStatus: 3
          })
        })
        .catch(() => {
          this.$message.warning('已取消')
        })
    },
    // 不通过
    rejectHandle (row) {
      this.$prompt('请输入不通过理由', '驳回申请', {
        inputType: 'textarea',
        inputPattern: /^.+$/,
        inputErrorMessage: '不通过理由为必填！'
      }).then(async ({ value }) => {
        try {
          await addGreenChannel({
            id: row.id,
            auditMsg: value,
            auditStatus: 2
          })
          this.$message.error(`已驳回${row.stuName}(录取编号：${row.examineNo}，贷款金额：${row.loanAmount})`)
          await this.renderTable()
        } catch (e) {
        }
      })
    },
    // 批量选择学生
    selectStudent (list) {
      const noReviewList = list.filter(({ auditStatus }) => Number(auditStatus) === 1)
      this.passList = noReviewList.map(({
                                          id,
                                          userId
                                        }) => {
        return {
          id: id,
          userId: userId,
          auditStatus: 3,
          auditUserId: JSON.parse(window.sessionStorage.getItem('userInfo')).id
        }
      })
    },
    // 批量通过
    passReviewHandle () {
      this.$confirm(`<p class="confirm-message" >批量通过选中的 <b class="bg-success">${this.passList.length}条</b> 绿色通道申请, 是否继续?</p>`, '批量通过', {
        dangerouslyUseHTMLString: true,
        type: 'success'
      })
        .then(async () => {
          try {
            await saveBatchGreenChannel({
              stuGreenChannelList: this.passList
            })
            this.$message.success(`成功批量通过${this.passList.length}条绿色通道申请`)
            await this.renderTable(1)
          } catch (e) {
            if (!e) {
              this.$message.error('批量审核失败，请重试！')
            }
          }
        })
        .catch(() => {
          this.$message('已取消')
        })
    },
    // 更新请求
    addGreenChannelHttp (params) {
      addGreenChannel(params).then(() => {
        this.$message({
          type: 'success',
          message: '操作成功'
        })
        this.renderTable()
      })
    },
    // 获取绿色通道列表
    async renderTable (pageNum) {
      await this.getTableData(getGreenChannelList, pageNum)
    },
    cancel (row) {
      const _type = this.$http.getParamName(row.channelType, this.$store.state.systemParam.greenRoadStatus)
      const _loanAmount = row.loanAmount ? `，金额：${row.loanAmount}` : ''
      this.$confirm(`<p class="confirm-message" >是否确认撤销 <b class="danger">${row.stuName}(录取编号：${row.examineNo}，类型：${_type}${_loanAmount})</b> 的绿色通道申请？</p>`, '通过后撤销', {
        dangerouslyUseHTMLString: true
      }).then(async () => {
        try {
          await cancelGreenChannelOffer(row.id)
          this.$message.success('撤销成功！')
          await this.renderTable()
        } catch (e) {
        }
      })
    }
  }
}
</script>
