var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{attrs:{"queryInfo":_vm.queryInfo,"tool-list":['grade', 'college', 'major','keyword'],"insert-select-all":['college', 'major']},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"审核状态","label-width":"80"}},[_c('el-select',{attrs:{"clearable":"","size":"small"},model:{value:(_vm.queryInfo.auditStatus),callback:function ($$v) {_vm.$set(_vm.queryInfo, "auditStatus", $$v)},expression:"queryInfo.auditStatus"}},_vm._l((_vm.$store.state.systemParam.reviewStatus),function(ref){
var paramValue = ref.paramValue;
var label = ref.label;
return _c('el-option',{key:paramValue,attrs:{"label":label,"value":paramValue}})}),1)],1),_c('el-form-item',{attrs:{"label":"申请类型","label-width":"80"}},[_c('el-select',{attrs:{"clearable":"","size":"small"},model:{value:(_vm.queryInfo.channelType),callback:function ($$v) {_vm.$set(_vm.queryInfo, "channelType", $$v)},expression:"queryInfo.channelType"}},_vm._l((_vm.$store.state.systemParam.greenRoadStatus),function(ref){
var paramValue = ref.paramValue;
var paramName = ref.paramName;
return _c('el-option',{key:paramValue,attrs:{"label":paramName,"value":paramValue}})}),1)],1)],1),_c('div',{staticClass:"header-button"},[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['greenRoad:review']),expression:"['greenRoad:review']"}],attrs:{"type":"success","size":"small","disabled":!_vm.passList.length},on:{"click":_vm.passReviewHandle}},[_vm._v("批量通过 ")])],1)]},proxy:true}])},[_c('el-table',{ref:"multipleTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""},on:{"selection-change":_vm.selectStudent}},[_c('el-table-column',{attrs:{"type":"selection","width":"40"}}),_c('el-table-column',{attrs:{"prop":"examineNo","label":"录取编号","width":"90"}}),_c('el-table-column',{attrs:{"prop":"stuName","label":"学生姓名","width":"100"}}),_c('el-table-column',{attrs:{"prop":"collegeName","label":"院系","width":"200"}}),_c('el-table-column',{attrs:{"prop":"majorName","label":"专业","min-width":"200"}}),_c('el-table-column',{attrs:{"prop":"className","label":"班级","min-width":"200"}}),_c('el-table-column',{attrs:{"prop":"managerUserName","label":"辅导员","width":"100"}}),_c('el-table-column',{attrs:{"prop":"stuName","label":"申请类型","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$http.getParamName(row.channelType, _vm.$store.state.systemParam.greenRoadStatus))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"loanAmount","label":"贷款金额","width":"100"}}),_c('el-table-column',{attrs:{"label":"状态","width":"80","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.auditStatus)?_c('el-tag',{attrs:{"type":_vm.$store.state.systemParam.reviewStatus[row.auditStatus-1].btnType}},[_vm._v(" "+_vm._s(_vm.$store.state.systemParam.reviewStatus[row.auditStatus - 1].label)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","label":"申请时间","width":"160"}}),_c('el-table-column',{attrs:{"label":"操作","width":"220","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showDialog(row.id)}}},[_vm._v("查看详情 ")]),(Number(row.auditStatus) === 1)?[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['greenRoad:review']),expression:"['greenRoad:review']"}],attrs:{"type":"success","size":"mini"},on:{"click":function($event){return _vm.passHandle(row.id)}}},[_vm._v("通过 ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['greenRoad:review']),expression:"['greenRoad:review']"}],attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.rejectHandle(row)}}},[_vm._v("不通过 ")])]:_vm._e(),(Number(row.auditStatus) === 3)?[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['greenRoad:cancel']),expression:"['greenRoad:cancel']"}],attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.cancel(row)}}},[_vm._v("通过后撤销 ")])]:_vm._e()]}}])})],1),(_vm.visibleDialog)?_c('review-detail',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }